import {Component, Mixins, Prop, Watch} from "vue-property-decorator";

import CmsAccordion from "@/commoncomponents/CmsAccordion/CmsAccordion.vue";
import CmsCardCarousel from "@/commoncomponents/CmsCardCarousel/CmsCardCarousel.vue";
import CmsCardComponent from "@/commoncomponents/CmsCardComponent/CmsCardComponent.vue";
import CmsLabelComponent from "@/commoncomponents/CmsLabelComponent/CmsLabelComponent.vue";
import CmsTagComponent from "@/commoncomponents/CmsTagComponent/CmsTagComponent.vue";
import BreadcrumbComponent from "@/commoncomponents/breadcrumbComponent/BreadcrumbComponent.vue";
import MobileScreenUnavailable from "@/commoncomponents/mobileScreenUnavailable/MobileScreenUnavailable.vue";
import DeviceWidthCheckerMixin from "@/mixins/deviceWidthChecker.mixin";
import Checkmark from "../../../assets/images/cms/checkmark.svg";
import ChevronRight from "../../../assets/images/cms/chevron_right_nav_link.svg";
import Bullet from "../../../assets/images/cms/link_bullet_blue.svg";
import ResourceLink from "../../../assets/images/cms/resource_link.svg";
import SmileyFace from "../../../assets/images/cms/smiley_face.svg";
import AcademicConnections from "../../../assets/images/cms/academic_connections.svg";
import SetUpIcon from "../../../assets/images/cms/man_woman.svg";
import MaterialsIcon from "../../../assets/images/cms/materials_box.svg";
import DownloadIcon from "../../../assets/images/cms/download_link_blue.svg";
import InfoIcon from "../../../assets/images/cms/information_gray.svg";
import CloseIcon from "../../../assets/images/cms/close_icon.svg";
import LeadershipIcon from "../../../assets/images/cms/leadership.svg";
import Printer from '../../../assets/images/cms/print.svg';

import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import APP_CONST from "@/constants/AppConst";
import {
  ActivityPageData,
  activityResponseToData,
  ActivityType,
  checkCMSEnabledReRoute,
  getCopyrightMessage,
  isAuthorizedContent
} from "@/utilities/cmsUtilities";
import axios from "axios";
import CmsContentfulComponent from "@/commoncomponents/CmsContentfulComponent/CmsContentfulComponent.vue";
import CmsNotAuthorizedComponent from "@/commoncomponents/CmsNotAuthorizedComponent/CmsNotAuthorizedComponent.vue";
import {
  getActivityRichTextFieldsGraphQLQuery,
  getContentfulGraphQLQuery,
  getRelatedContentfulGraphQLQuery
} from "@/utilities/contentfulGraphQLHelpers";
import {ActivityHeaderConfig, getActivityHeaderConfig} from "@/utilities/cms/activityUtils";
import programListStore from "@/store/modules/programList";
import CmsVideoPreviewCardComponent
  from '@/commoncomponents/CmsVideoPreviewCardComponent/CmsVideoPreviewCardComponent.vue';


@Component({
  components: {
    "bread-crumb": BreadcrumbComponent,
    "mobile-unavailable-screen": MobileScreenUnavailable,
    "cms-card": CmsCardComponent,
    "collapsible-layout-card": CmsAccordion,
    "cms-tag-list": CmsTagComponent,
    "cms-content-label": CmsLabelComponent,
    "card-carousel": CmsCardCarousel,
    "cms-contentful-render": CmsContentfulComponent,
    "bouncing-preloader": BouncingPreloaderComponent,
    'cms-not-authorized': CmsNotAuthorizedComponent,
    'preview-video': CmsVideoPreviewCardComponent
  }
})
export default class ActivityLandingPage extends Mixins(
  DeviceWidthCheckerMixin
) {
  readonly STYLE = APP_CONST.STYLE;

  //Images from assets folder
  checkmarkImage = Checkmark;
  resourceLinkImage = Bullet;
  navLinksChevron = ChevronRight;
  linkIcon = ResourceLink;
  smileyFace = SmileyFace;
  academicIcon = AcademicConnections;
  setUpIcon = SetUpIcon;
  materialsIcon = MaterialsIcon;
  downloadIcon = DownloadIcon;
  infoIcon = InfoIcon;
  closeIcon = CloseIcon;
  printIcon = Printer;
  leadershipIcon = LeadershipIcon;

  buttonType: "VIEW" | "DOWNLOAD" | "LINK" = "DOWNLOAD";

  showFeedback: boolean = true;
  activityId: string = '';
  activityType: ActivityType = ActivityType.OLD;
  @Prop()
  resourceIdQuery: string | undefined;

  pageData: ActivityPageData = {
    title: "",
    itemTagline: "",
    id: "",
    heroImage: "",
    objectivesList: [],
    tagList: [],
    labelData: [],
    navLinks: [],
    relatedContentCards: [],
    url: "",
    itemDescription: "",
    activityType: ActivityType.OLD,
  };
  loading: boolean = true;
  isAuthorized: boolean = true;


  headerHeight: number = 0;
  headerOffsetTop = 0;

  isPrinting: boolean = false;

  activityHeaderConf: ActivityHeaderConfig = getActivityHeaderConfig(ActivityType.THEME);

  /* istanbul ignore next */
  get lxEnabled() {
    return programListStore.appSetting;
  }
  @Watch('lxEnabled', { immediate: true, deep: true })
  cmsFeatureFlag(store: any) {
    if (checkCMSEnabledReRoute(store)) {
      this.$router.push({ path: '/' });
    }
  }

  get isActivityTypeBlast() {
    return this.activityType === ActivityType.BLAST;
  }

  get displayCurriculum() {
    return !this.isActivityTypeBlast;
  }

  get displayPrepAccordion() {
    return !this.isActivityTypeBlast;
  }

  get displayDemonstrationVideos() {
    return this.isActivityTypeBlast;
  }

  updateHeaderHeight(isFirst?: boolean) {
    const elArr = this.$el.getElementsByClassName("activity-header");
    const headerEl = document.getElementsByClassName('header-fixed');
    if (elArr.length) {
      const element = elArr[0];
      const elHeight = element.clientHeight;
      if (elHeight > 10) {
        this.headerHeight = element.clientHeight;
      } else if (isFirst) {
        setTimeout(() => {
          this.updateHeaderHeight(isFirst);
        }, 100);
      }

      this.headerOffsetTop = headerEl.length
        ? headerEl[0].clientHeight
        : 0;
    } else if (isFirst) {
      setTimeout(() => {
        this.updateHeaderHeight(isFirst);
      }, 100);
    }
  }

  async fetchData() {
    const url = `${APP_CONST.CONTENTFUL_GRAPHQL_URL}${APP_CONST.CONTENTFUL_GRAPHQL_SPACE}${APP_CONST.CONTENTFUL_GRAPHQL_ENV}`;
    const headers = { Authorization: `Bearer ${APP_CONST.CONTENTFUL_GRAPHQL_TOKEN}` };
    const method = 'post';
    return axios({
      url: url,
      headers: headers,
      method: method,
      data: {
        query: getContentfulGraphQLQuery(this.activityType, this.activityId)
      }
    }).then(activityResult => {
      const activityPageData = activityResult;
      // Fetch related content
      axios({
        url: url,
        headers: headers,
        method: method,
        data: {
          query: getRelatedContentfulGraphQLQuery(this.activityType, this.activityId, true)
        }
      }).then((relatedResult) => {
        axios({
          url: url,
          headers: headers,
          method: method,
          data: {
            query: getActivityRichTextFieldsGraphQLQuery(this.activityType, this.activityId)
          }
        }).then((richTextResult) => {
          // Merge related content into course data
          activityPageData.data.data[this.activityType] = {
            ...activityPageData.data.data[this.activityType],
            ...relatedResult.data.data[this.activityType],
            ...richTextResult.data.data[this.activityType]
          };
          const structuredData = activityResponseToData(activityPageData, this.activityType);
          this.isAuthorized = isAuthorizedContent(structuredData);

          this.pageData = {
            ...structuredData
          };
          this.loading = false;

          return structuredData;

        }).catch(err => {
          console.error(`Failed to Fetch Rich Text Field Content`, err);
        });
      }).catch(err => {
        console.error(`Failed to Fetch Related Content`, err);
      });
    }).catch(err => {
      console.error(`Failed to Fetch Landing Page`, err);
    });
  }

  updateActivityHeader() {
    this.activityHeaderConf = getActivityHeaderConfig(this.activityType);
  }

  async updatedId() {
    const id: string = this.$route.query.id as string || '';
    const typeString: string = this.$route.query.activityType as string || '';
    const type: ActivityType = typeString as ActivityType;
    this.activityId = id;
    this.activityType = type;
    this.updateActivityHeader();
    this.loading = true;
    this.fetchData();
  }

  mounted() {
    this.updateHeaderHeight(true);
  }

  beforeMount() {
    const id = this.$route.query.id;
    const typeString: string = this.$route.query.activityType as string || '';
    if (typeString.length) {
      const type: ActivityType = typeString as ActivityType;
      this.activityType = type;
      this.updateActivityHeader();
    }
    if (id) {
      this.activityId = id.toString();
    }
    this.$watch("$route", this.updatedId);
    this.fetchData();
  }

  created() {
    window.addEventListener("resize", this.updateHeaderHeight as any);
    window.addEventListener("beforeprint", (event) => {
      console.log('before print', event);
      this.isPrinting = true;
    });
    window.addEventListener("afterprint", (event) => {
      console.log('after print', event);
      this.isPrinting = false;
      this.$forceUpdate();
    });

  }

  destroyed() {
    window.removeEventListener("resize", this.updateHeaderHeight as any);
  }

  downloadAllPrintables() {
    const currentLocation = window.location.href;
    if (this.pageData.printables) {
      this.pageData.printables.forEach((item, index) => {
        //Hitting window.open multiple times immediatley triggers chrome's pop up warning.
        // Use window.location instead to trigger the "Multiple Downloads" warning
        if (item.value) {
          setTimeout(() => {
            window.location.href = item.value;
          }, index * 700);
        }
      });
      window.location.href = currentLocation;
    }
  }

  dismissFeedback() {
    this.showFeedback = false;
  }

  getCopyrightMessage = getCopyrightMessage;

  handlePrint() {
    this.isPrinting = true;
    this.$forceUpdate();
    this.$nextTick(() => {
      setTimeout(() => {
        print();
        this.$forceUpdate();

      }, 1000);
    });
  }
}
